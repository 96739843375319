import { type User } from '@harta-io/drizzle'
import { type SerializeFrom } from '@remix-run/node'
import { useRouteLoaderData } from '@remix-run/react'
import { type loader as rootLoader } from '#app/root.tsx'

function isUser(user: any): user is SerializeFrom<typeof rootLoader>['user'] {
  return user && typeof user === 'object' && typeof user.id === 'string'
}

export function useOptionalUser() {
  const data = useRouteLoaderData<typeof rootLoader>('root')
  if (data == null) {
    return undefined
  }
  if (!isUser(data.user)) {
    return data.anonymousUser
  }
  return data.user
}

export function useUser(): SerializeFrom<User> | null | undefined {
  const data = useRouteLoaderData<typeof rootLoader>('root')
  if (data == null) {
    return undefined
  }
  return data.user
}

export function useUserAvatar() {
  const data = useRouteLoaderData<typeof rootLoader>('root')
  if (!data || !data.user?.userAvatar) {
    return undefined
  }
  return data.user.userAvatar
}
